import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO
      title={data.site.siteMetadata.title}
      description={data.site.siteMetadata.description}
      slug={"/"}
      article={false}
    />

    <Avatar
      src={"https://www.gravatar.com/avatar/9512465540830de4b91ac3931f1ddfb5"}
    />
    <Name>Hiroshi Sugawara</Name>
    <About>
      <p>Web Developer</p>
      <p>
        I am living near the sea in Japan.
      </p>
    </About>
  </Layout>
)

const Avatar = styled.img`
  display: block;
  width: 80px;
  height: 80px;
  margin: 320px auto 16px;
  border-radius: 50%;
`

const Name = styled.div`
  width: 600px;
  margin: 16px auto;
  text-align: center;
`

const About = styled.div`
  width: 600px;
  margin: 16px auto;
`

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`

export default IndexPage
