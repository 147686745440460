import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Static from "../components/Static"

export default ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={"Privacy Policy | About 5000164"} description={"Privacy Policy"} slug={"/privacy/"} article={false}/>
      <Static
        title={"Privacy Policy"}
        content={
          "<p>This website uses cookies.</p>" +
          "<p>This website uses <a href='https://marketingplatform.google.com/about/analytics/'>Google Analytics</a>." +
          "See " +
          "<a href='https://www.google.com/policies/privacy/partners/'>How Google uses data when you use our partners' sites or apps</a>" +
          "  to know how it collects and processes data.</p>"
        }
      />
    </Layout>
  )
}
